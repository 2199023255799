@import 'styles/variables.less';

.br-cash-records__actions {
  display: flex;
  justify-content: flex-end;
}

.br-cash-records__actions .ant-btn:not(.ant-btn-success) {
  color: @text-color-31;
}

.br-cash-records__actions :not(:last-child) {
  margin-right: 9px;
}

.br-cash-records__actions {
  margin-top: 28px;
}

.br-cash-records__actions .ant-picker.ant-picker-range {
  height: 34px;
}

.br-cash-records__actions .anticon {
  font-size: 18px;
  display: flex;
}

.br-cash-records__actions__export.ant-btn {
  background-color: @background-color-18;
}

.br-cash-records .br-searchable-component__header {
  padding-top: 0;
}

.ant-popover-buttons {
  display: flex;
  justify-content: end;
  border-radius: 4px;
}

.br-star__international-order-icon {
  display: flex;
  gap: 4px;
}

.br-cash-record__fawry-pos-title {
  background-color: @background-color-62;
  border-radius: 100px;
  padding: 4px 8px;
  margin-left: 6px;
  font-size: 12px;
  font-family: @lato-font-700;
  & svg {
    margin-bottom: 3px;
    margin-right: 4px;
  }
}
