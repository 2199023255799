@import 'styles/variables.less';

@primary-color: @teal-500;
@layout-header-background: @background-color;
@text-color: @text-gray-dark;

.ant-steps-item-custom.ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon {
  color: @text-color-29;
}

.ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: @text-color-29;
}

.ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.ant-btn span {
  font-family: @lato-font-700;
}

.ant-btn:not(.ant-btn-circle) span {
  font-family: @lato-font-700;
}

.ant-btn :not(.ant-btn-circle) {
  border-radius: 8px;
}

.ant-collapse {
  background-color: white;
}

.ant-collapse-content {
  background-color: #f7fafc;
}

.ant-modal-header {
  border-radius: 8px 8px 0 0;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  background-color: @bg-gray-light;
  border-radius: 0 0 8px 8px;
}
// .ant-btn-primary:hover {
//   background-color: #39b54a;
//   border-color: #39b54a;
// }

.ant-layout-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-left: 25px;
}

.ant-select-selection-placeholder {
  opacity: 0.5 !important;
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-table-thead > tr > th {
  white-space: nowrap;
}

.ant-tabs {
  display: flex;
  flex-direction: column;
}

.ant-tabs-content {
  height: 100%;
}

// Spin
.ant-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ant-spin-nested-loading {
  min-width: 0;
}

.ant-spin-nested-loading,
.ant-spin-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: unset;
}

.ant-typography.ant-typography-secondary {
  color: #8f9bb3;
}

.ant-table-pagination.ant-pagination {
  margin-right: 20px;
}

.ant-input::placeholder {
  font-size: 15px;
  font-weight: 500;
}

.ant-table-content {
  border-radius: 4px;
  border: 1px solid rgba(224, 224, 227, 0.6);
}

.ant-table-thead > tr > th {
  color: #8f9bb3;
}

// Pagination

.ant-pagination {
  display: flex;
  justify-content: center;
}

.ant-pagination > .ant-pagination-item > a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-pagination .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: 1px solid @gray-200;
  box-shadow: 0px 2px 2px @box-shadow;
  border-radius: 4px;
}

.ant-pagination-disabled .ant-pagination-item-link {
  background: @bg-gray-light;
  border: 1px solid @gray-200;
}

.ant-pagination-item {
  vertical-align: middle;
  .font({.button()});
  border-radius: 4px;
  box-shadow: @shadow-xs;
  border: 1px solid @gray-300;
  height: 36px;
  min-width: 38px;
  a {
    color: @text-gray-dark;
  }
}

.ant-pagination-item-active {
  border-color: @teal-500;
}

.ant-pagination .ant-pagination-item-active a,
.ant-pagination .ant-pagination-item:focus a {
  color: @teal-500;
}

// Button

.ant-btn:not(.ant-btn-circle) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  border-radius: 4px;
  line-height: 14px;
  box-shadow: none;
  text-shadow: none;
}

.ant-btn-danger,
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  background-color: @background-color-15;
}

.ant-btn[disabled].ant-btn-primary {
  background: @background-color-19;
  border: 1px solid @border-color-3;
  color: @ant-icon-down-color;
}

.ant-btn[disabled].ant-btn-primary span {
  font-family: @lato-font-700;
}

//for cursor not be in top but in the same line with placeholder
.ant-select-selector input {
  margin-top: 8px;
}

//for : in form group
.ant-form-item-label > label::after {
  display: none;
}

.ant-form-item > .ant-form-item-label {
  margin-bottom: 6px;
  text-align: start;
}
.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  // background-color: #39b54a;
  // border-color: #39b54a;
  border-radius: 2px;
}

.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  border-radius: 2px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #ffffff !important;
}

// Date picker - fix global style override that mixed with its height

table.ant-picker-content tbody {
  height: auto;
}

// Modal

.ant-modal-mask {
  z-index: 1020;
}

.ant-modal-wrap {
  z-index: 1020;
}

.ant-modal-content {
  // border: 2px solid white;
  border-radius: 8px;
}

.ant-modal-close {
  display: none;
}

// Tag

.ant-tag {
  font-family: @lato-font-400;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 4px;
}

.ant-tag-yellow {
  color: @tag-yellow-text-color;
  background: @tag-yellow-background-color;
  border-color: @tag-yellow-background-color;
}

.ant-tag-orange {
  color: @tag-orange-text-color;
  background: @tag-orange-background-color;
  border-color: @tag-orange-background-color;
}

.ant-tag-green {
  color: @tag-green-text-color;
  background: @tag-green-background-color;
  border-color: @tag-green-background-color;
}

.ant-tag-red {
  color: @tag-red-text-color;
  background: @tag-red-background-color;
  border-color: @tag-red-background-color;
}

.ant-tag-light-gray {
  color: @tag-light-gray-text-color;
  background: @tag-light-gray-background-color;
  border-color: @tag-light-gray-background-color;
}

.ant-tag-dark-gray {
  color: @tag-dark-gray-text-color;
  background: @tag-dark-gray-background-color;
  border-color: @tag-dark-gray-background-color;
}

// Form
.ant-row.ant-form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  width: 100%;
  min-height: 70px;
  margin-bottom: 0;
}

.ant-row.ant-form-item:not(:last-child) {
  margin-right: 22px;
}

.ant-form-item-explain,
 .ant-form-item-extra  {
  line-height: 1.2;
  margin-top: 2px;
  font-size: 12px;
  min-height: unset;
}

.ant-form-item-control,
.ant-form-item-control .ant-picker,
.ant-input-number {
  width: 100%;
}

.ant-form-item > .ant-form-item-label > label,
.ant-form-item > .ant-form-item-label > label > span {
  height: 20px;
  color: @text-gray-dark;
  margin-bottom: 0;
  .font({.body-medium()});
}

.br-forms-new-input .ant-form-item > .ant-form-item-label > label,
.br-forms-new-input .ant-form-item > .ant-form-item-label > label > span {
  width: 100%;
}

.ant-form-item-label > label.ant-form-item-required::before,
.ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #00b887;
}
//for : in form group
.ant-form-item-label > label::after {
  display: none;
}

.ant-form-item-has-error.ant-form-item-has-feedback
  .ant-form-item-children-icon,
.ant-form-item-has-success.ant-form-item-has-feedback
  .ant-form-item-children-icon {
  display: none;
}

.ant-form-item-has-error .ant-form-item-control-input-content {
  border-radius: 4px;
  box-shadow: 0 0 0 4px @red-100;
}

.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input:focus,
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ).ant-select-focused
  .ant-select-selector,
.ant-input-affix-wrapper-status-error:not(
    .ant-input-affix-wrapper-disabled
  ):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 4px @red-100;
}

.ant-form-item-has-error.ant-form-item-has-feedback .anticon-close-circle svg {
  background: transparent url('../assets/bosta-icons/alert-circle-red.svg')
    no-repeat 50% 50%;
  height: 16px;
  width: 16px;
}

.ant-form-item-has-error.ant-form-item-has-feedback .anticon-close-circle,
.ant-form-item-has-success.ant-form-item-has-feedback .anticon-check-circle {
  svg {
    color: transparent;
  }
}

// fixme: Check what its used for
.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: unset;
}

.ant-input-affix-wrapper,
.ant-select-selector,
.ant-form-item-control-input-content,
.ant-input {
  min-height: 36px;
}

.ant-input-affix-wrapper {
  padding: 0 11px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 2px 11px;
}

.ant-input:focus,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: 1px solid @teal-400;
  box-shadow: 0 0 0 4px @teal-200;
}

@media (max-width: 575px) {
  .ant-row.ant-form-item,
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: unset;
  }
}

// Input Password Select DropdownButton

.ant-input,
.ant-input-password,
.ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-picker,
.ant-btn.ant-dropdown-trigger:not(.ant-btn-primary) {
  border: 1px solid #d1d5db;
  border-radius: 4px;
}

.ant-btn.ant-dropdown-trigger .anticon.anticon-down {
  color: #00000040;
}

.ant-btn.ant-dropdown-trigger:not(.ant-btn-primary):not(.ant-btn-success):not(
    .ant-btn-secondry
  ):hover,
.ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector:hover {
  border-color: @primary-color;
}

.ant-input::placeholder {
  font-size: 15px;
  font-weight: 500;
}

//for cursor not be in top but in the same line with placeholder
.ant-select-selector input {
  margin-top: 8px;
}

.ant-select-selection-placeholder {
  opacity: 0.5 !important;
}

.ant-input[disabled].ant-input-disabled {
  background-color: @background-color-18;
  border-color: @border-color-18;
}

.ant-input::placeholder,
.ant-select-selection-placeholder,
.ant-picker-input input::placeholder {
  font-size: 13px;
  color: @text-color-32;
  opacity: 1 !important;
}

.ant-tooltip-inner {
  background: #2d3859;
  word-break: break-word;
  font-family: @lato-font-400;
  background-color: #2d3859;
  border-radius: 4px;
}

.ant-select-item-option-content {
  white-space: break-spaces;
  margin-bottom: 2px;
}

.ant-select-multiple {
  .ant-select-selection-item {
    height: auto;
  }

  .ant-select-selection-item-content {
    white-space: break-spaces;
  }
}

//ant-steps

.ant-checkbox-checked .ant-checkbox-inner {
  border-radius: 2px;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 3px solid @text-color-15;
  border-top: 0;
  border-left: 0;
}

//tabs
.ant-tabs-top > .ant-tabs-nav {
  margin: 0 0 27px 0;
}

.ant-tabs-tab-btn {
  font-family: @lato-font-700;
  font-size: 13px;
  color: @text-color-10;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: @lato-font-700;
}

.ant-btn-primary:hover {
  background-color: @primary-color;
  border-color: @primary-color;
  color: white;
}

//select
.ant-select:not(.ant-pagination-options-size-changer) .ant-select-arrow {
  background: transparent url('../assets/bosta-icons/Select-Arrow.svg')
    no-repeat 50% 50%;
  color: @gray-500;
  height: 16px;
  width: 12px;
  margin-top: -7px;
  & svg {
    visibility: hidden;
  }
}

.ant-select.ant-select-disabled .ant-select-arrow {
  background: transparent url('../assets/bosta-icons/DisabledSelectArrow.svg')
    no-repeat 50% 50%;
}

.ant-pagination-options-size-changer .ant-select-arrow {
  background: transparent url('../assets/bosta-icons/Dropdown.svg') no-repeat
    50% 50%;
  color: @gray-500;
  height: 14px;
  & svg {
    visibility: hidden;
  }
}

.ant-modal-title {
  font-family: @lato-font-700;
  color: @text-gray-dark;
}

.anticon-info-circle {
  color: @text-color-8;
  font-size: 12px;
  margin-left: 5px;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  background-color: @gray-50;
  border: 1px solid @gray-200;
  padding: 16px 20px;
  border-radius: 0px 0px 8px 8px;
}

.ant-modal-header {
  padding: 16px 20px;
  height: 68px;
  display: flex;
  align-items: center;
  box-shadow: @border-bottom;
  border-bottom: 0;
  border-radius: 8px 8px 0px 0px;
  border-bottom: 0;
}

.ant-picker-suffix .anticon-calendar,
.ant-picker-separator .anticon-swap-right {
  svg {
    fill: @icon-default;
  }
}

.ant-picker-separator .anticon-swap-right {
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-active:hover {
  background-color: @gray-50;
}

.ant-dropdown-menu-item {
  padding: 9px 8px;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background-color: @white;
}

.ant-dropdown-menu-item-disabled .ant-dropdown-menu-title-content span {
  color: @gray-400;
}

.ant-dropdown-menu:not(.ant-dropdown-menu-submenu) {
  padding: 8px;
  margin: 0;
  border-radius: 4px;
  box-shadow: @shadow-md;

  li {
    border-radius: 4px;
  }
}

.ant-dropdown-menu-submenu-title {
  padding: 9px 8px;
}

.rc-virtual-list-scrollbar {
  width: 12px !important;
  background: @gray-50 !important;
  border-left: 1px solid @gray-200 !important;
}
.rc-virtual-list-scrollbar-thumb {
  background-color: @gray-200 !important;
  border-radius: 8px;
  border: 4px solid rgba(@black, 0) !important;
  background-clip: padding-box;
}

.rc-virtual-list-scrollbar-show {
  background-clip: padding-box;
  background-color: @gray-50 !important;
  display: block !important;
}

.ant-message {
  z-index: 9999;
}

// Radio
.ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
.ant-radio-wrapper .ant-radio-input:focus + .ant-radio-inner {
  border-color: @deep-teal;
}

.ant-radio .ant-radio-checked .ant-radio-inner {
  border-color: @deep-teal;
}

.ant-radio .ant-radio-checked::after {
  border: 1px solid @deep-teal;
}

.ant-radio.ant-radio-checked .ant-radio-inner {
  border: 5px solid @deep-teal;
}

.ant-radio .ant-radio-inner::after {
  background-color: @white;
  transform: scale(0.4);
}

.ant-radio.ant-radio-disabled:hover,
.ant-radio.ant-radio-disabled {
  .ant-radio-inner {
    border: 1px solid @gray-200;
  }
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  background-color: @background-color-49;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-disabled:hover {
  background-color: @background-color-49;
}
.ant-radio-button-wrapper:hover {
  box-shadow: @shadow-xs-green;
  z-index: 1;
}

.ant-radio-button-wrapper:hover {
  box-shadow: @shadow-xs-green;
  z-index: 1;
}

.ant-radio-button-disabled {
  background-color: @bg-disabled;
  border: 1px solid @gray-200;
  span {
    color: @text-gray-light;
  }
}

// Checkbox

.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  border: unset;
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled)
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: @teal-500;
  border-color: @teal-500;
  border-radius: 4px;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled span {
  color: @text-gray-light;
}

.ant-checkbox-wrapper span {
  color: @text-gray-dark;
}

.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner {
  border-radius: 4px;
}

.ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: @teal-500 !important;
  border: 1px solid @teal-500 !important;
}

.ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: @white;
  border-color: @white;
  height: 1px;
  width: 8px;
}

.ant-drawer-title {
  display: flex;
  justify-content: space-between;
  .font({.display-xs});
}
