@import 'styles/variables';

.sorting-facility__sorting-facility-container {
  width: 100%;
}

.sorting-facility__sorting-facility-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 500;
}

.sorting-facility__dispatching__header h3 {
  margin-left: 10px;
}

.sorting-facility__general-cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.sorting-facility__dispatching-title {
  min-width: 140px;
}

.sorting-facility__dispatching-total {
  color: @teal-500;
}

.sorting-facility__dispatching-card {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 2px 4px @gray-200;
  border-radius: 16px;
  padding: 24px;
  border: 1px solid @white;
  margin-top: 25px;
  position: relative;
  background-color: @white;
}

.sorting-facility__dispatching-card::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 47%;
  width: 100%;
  height: 1px;
  background-color: @gray-200;
}

.ops_control__delevries-count {
  display: flex;
  justify-content: space-between;
  width: 40%;
  align-items: center;
  margin-top: 10px;
}

.sorting-facility__dispatching__header {
  display: flex;
  align-items: flex-start;
}

.sorting-facility__dispatching-info-container {
  display: flex;
  width: 100%;
}

.sorting-facility__backlog-banner {
  background-color: @red-50;
  border: 1px solid @red-200;
  padding: 12px 16px;
  border-radius: 16px;
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.sorting-facility__backlog-number {
  align-items: center;
  height: 100%;
  display: flex;
}

.sorting-facility__backlog-number h3 {
  margin: 0px;
}

.ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab-btn {
  color: @gray-500;
}

.ops_control__table-header {
  display: flex;
  align-items: center;
}

.export-data-button:hover {
  cursor: pointer;
}

.sorting-facility__backlog-number svg {
  margin-right: 10px;
  margin-left: 5px;
}

.sorting-facility__backlog-number svg path,
.sorting-facility__crumb-bar-data h6 svg path {
  fill: @icon-disabled;
}

.parcels-amount {
  color: @text-red;
}

.ops_control__table-header {
  display: flex;
  align-items: center;
}

.ops_control__table-header svg {
  margin-right: 4px;
  align-items: flex-end;
}

.sorting-facility__crumb-bar-data {
  display: flex;
  width: 98%;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.gradiant-slider-container {
  width: 90%;
}

.sorting-facility__pending_delievries {
  display: flex;
  width: 60%;
  justify-content: space-between;
}

.sorting-facility__pending_delievries > * {
  width: 30%;
}

.sorting-facility__pending_delievries::after {
  margin-right: 3%;
  content: '';
  width: 2px;
  height: 100%;
  background-color: @gray-200;
  box-shadow: 0px 2px 4px @box-shadow;
  border-radius: 8px;
}

.sorting-facility__lost-or-damaged-deliveries {
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.sorting-facility__lost-or-damaged-deliveries > * {
  height: 101px;
  width: 48%;
}

.sorting-facility__orderes-overview-table {
  border-radius: 16px;
  margin-top: 25px;
}

.sorting-facility__orderes-overview-table .br-table-component,
.sorting-facility__orderes-overview-table .ant-table-wrapper {
  border-radius: 16px;
  border-color: @white;
  background-color: @white;
}

.br-sorting-facility__order-table-column {
  width: 120px;
}

.br-sorting-facility__order-table-column .br-delivery-type {
  width: 80%;
  margin: 0 auto;
  text-align: left;
}

.ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab-btn {
  color: @gray-500;
}
