@import 'styles/variables';

.br-hub-transfer-table__missing-scans {
  background-color: @yellow-50;
}

.br-hub-transfer-multipackage__collapse-icon {
  > svg > * {
    color: @text-gray-dark;
  }

  &.br-hub-transfer-multipackage__collapse-icon__active {
    transform: scale(-1);
  }
}

.br-hub-transfer-multipackage__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  max-width: 240px;
  margin-left: 52px;

  > span:first-child {
    color: @text-gray;
  }
}

.br-hub-transfer-multipackage__scan-status {
  color: @icon-yellow-dark;

  &.br-hub-transfer-multipackage__scanned {
    color: @green-600;
  }
}

.br-hub-transfer-multipackage__row {
  > .ant-table-cell {
    background-color: @yellow-50;
    padding: 0;
  }
}
