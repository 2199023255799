@import 'styles/variables';

.br-order-info-header__options-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}

.br-live-ops__actions-menus__item svg {
  color: @background-color;
  width: 16px;
  height: 16px;
}

.br-order-info-header__tn-state-name-container {
  width: 40%;
  display: flex;
  align-items: center;
  & .br-order-info-header__tn {
    font-size: 20px;
    font-family: @lato-font-900;
    margin-right: 12px;
  }

  & .br-order-info-header__fxf {
    padding: 1px 10px 2px;
    margin-right: 12px;
    border-radius: 10px;
    background-color: @background-color-64;
    color: @text-color-15;
    font-size: 13px;
    line-height: 16px;
  }

  & .br-order-info-header__ccod-tag {
    padding: 4px 10px;
    margin-left: 12px;
    border-radius: 10px;
    background-color: @purple-100;
    color: @purple-800;
    font-size: 13px;
    line-height: 16px;
  }

  & .br-order-info-header__state {
    margin-right: 2%;
    font-family: @lato-font-700;
    font-size: 12px;
    padding: 4px 10px;
    border-radius: 10px;
    width: max-content;
  }

  & .br-order-info-header__state.br-fawry-tag {
    margin-left: 12px;
  }

  & .br-state-blue-group {
    background-color: @background-color-21;
    border-color: @background-color-21;
    color: @text-color-37;
  }
  & .br-state-green-group {
    background-color: @background-color-22;
    border-color: @background-color-22;
    color: @text-color-29;
  }
  & .br-state-purple-group {
    background-color: @background-color-23;
    border-color: @background-color-23;
    color: @text-color-31;
  }
  & .br-state-red-group {
    background-color: @background-color-24;
    border-color: @background-color-24;
    color: @text-color-27;
  }
  & .br-state-yellow-group {
    background-color: @background-color-25;
    border-color: @background-color-25;
    color: @text-color-36;
  }
}

.br-order-info-header__options {
  display: flex;
  flex-direction: row;
  & button,
  a {
    margin-left: 8px;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    & span {
      font-size: 14px;
      font-family: @lato-font-700;
      color: @text-color-47;
    }
  }

  & a.br-order-info-header__options-business-view-button {
    background: @background-color-52;
    font-size: 14px;
    font-family: @lato-font-700;
    color: @text-color-47;
    border-radius: 4px;
    height: 34px;

    & svg {
      border-radius: 0;
      margin-right: 12px;
    }
  }

  & button.br-order-info-header__options-print-awb-button {
    background: @background-color-53;
    color: white;
    & span {
      margin-left: 12px;
      color: white;
    }
  }
}

.br-order-info-header__order-info-section {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  & div {
    margin-right: 24px;
    & .br-order-info-header__order-info-title {
      font-size: 12px;
      font-family: @lato-font-700;
      color: @text-color-45;
      margin-right: 8px;
    }
    & .br-order-info-header__order-info-value {
      font-size: 12px;
      font-family: @lato-font-700;
      color: @text-color-46;

      & a {
        color: @text-color-48;
        font-family: @lato-font-400;
        text-decoration: underline;
        font-size: 14px;
      }
    }
    & .br-order-info-header__order-info-value.br-order-info__date-value {
      font-family: @lato-font-400;
      font-size: 14px;
    }
  }
}

.br-delivery-details__return-package-image {
  & p {
    margin-bottom: 0;
    margin-top: 8px;
    font-size: 12px;
  }
}

.br-order-header__exceeded-sla-tag {
  background-color: @background-color-24;
  color: @text-color-27;
  border-radius: 8px;
  padding: 4px 8px;
  margin-left: 8px;
  font-family: @lato-font-700;
}

.br-order-info-header__dropdown-menu
  .ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  width: 186px;
  padding: 12px 8px;
  & li {
    padding: 8px;
    & div {
      display: flex;
      align-items: center;
      & svg {
        margin-right: 8px;
      }
      & span {
        color: @text-color-31;
        font-size: 13px;
        font-family: @lato-font-700;
      }
    }
  }
 
  .br-delivery-details__cancel-action {
    & .anticon > svg {
      fill: transparent;
    }

    & span.br-order-info-header__reschedule-title {
      color: @red-600;
    }
  }
}

.br-order-info__soho-tag.ant-tag {
  font-family: @lato-font-700;
  border-radius: 10px;
  border-color: @gray-100;
  font-size: 12px;
  margin-right: 0;
  margin-left: 12px;
  color: @gray-800;
  background: @gray-100;
}

@media only screen and (max-width: @sm) {
  .br-order-info-header__options-container,
  .br-order-info-header__options,
  .br-order-info-header__order-info-section {
    flex-direction: column;
  }

  .br-order-info-header__options button,
  .br-order-info-header__options a {
    margin-left: 0;
    margin-top: 10px;
    justify-content: center;
  }

  .br-order-info-header__dropdown-menu
    &
    .ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
    width: 100%;
  }
}
