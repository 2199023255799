@import 'styles/variables';

.br-searchable-component__empty,
.br-searchable-component__empty-search-view {
  height: 325px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.br-searchable-component__empty img {
  height: 43px;
}

.br-searchable-component__empty-search-view img {
  height: 60px;
}

.br-searchable-component__empty__title,
.br-searchable-component__empty-search-view__title {
  color: @text-color;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  padding-top: 14px;
}

.br-searchable-component__header {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 39px;
  padding-bottom: 23px;
  & .display-flex {
    justify-content: flex-end;
  }
}

.br-searchable-component__empty__title,
.br-searchable-component__empty-search-view__title,
.br-searchable-component__header
  .br-searchable-component__header__filter-button
  span,
.br-searchable-component__header h4.ant-typography,
.br-searchable-table__listing
  .ant-table-container
  table
  > thead
  > tr:first-child
  th {
  font-family: @lato-font-700;
}

.br-searchable-component__header h4.ant-typography {
  font-size: 17px;
}

.br-searchable-component__header__search-input
  .ant-input-suffix
  .ant-input-search-icon {
  display: none;
}

.br-searchable-component__header__search-input.ant-input-search {
  height: 31px;
  width: 241px;
  border: 1px solid @border-color-17;
  border-radius: 4px;
  background-color: @text-color-15;
}

.br-searchable-component__header__search-input .ant-input-prefix {
  color: @text-color-30;
  font-size: 14px;
}

.br-searchable-component__header__search-input .ant-input::placeholder,
.br-searchable-component__header__search-input.ant-input-search input {
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
}

.br-searchable-component__header__filter-button {
  margin-left: 10px;
}

.br-searchable-component__header__search-input.ant-input-search input {
  color: @text-color;
}

.br-searchable-component__header__filter-button.ant-btn {
  height: 32px;
  width: 79px;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.br-searchable-component__header__filter-button.ant-btn span {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
}

.br-searchable-table__listing .ant-table-tbody {
  height: auto;
  overflow: hidden;
}

.br-searchable-table__listing .ant-tag {
  color: #2d3859;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  border-radius: 8px;
}

.br-searchable-table__listing .ant-table-selection-column {
  width: 6%;
}

.br-searchable-table__listing .ant-table .ant-table-cell {
  height: 20px;
  color: @text-color-47;
  font-size: 13px;
  line-height: 20px;
  font-family: @lato-font-400;
  padding: 12px;
}

.br-searchable-table__listing
  .ant-table-container
  table
  > thead
  > tr:first-child
  th {
  height: 55px;
  border-radius: 8px 8px 0 0;
  background-color: @background-color;
  color: @text-color;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
}

.br-searchable-table__listing .ant-table-content {
  border: 1px solid @border-color-18;
  border-radius: 8px;
}

.br-searchable-table__listing .ant-table-tbody > tr > td {
  border-bottom: 1px solid @border-color-18;
}

.br-searchable-table .ant-input-group-addon-hide .ant-input-group-addon {
  display: none;
}

.br-searchable-table__listing .ant-pagination-item {
  border: 0;
  vertical-align: middle;
  font-size: 13px;
}

.br-searchable-table__listing .ant-pagination-prev .ant-pagination-item-link,
.br-searchable-table__listing .ant-pagination-next .ant-pagination-item-link {
  display: flex;
  align-items: center;
  border: 0;
  vertical-align: middle;
}

.br-searchable-table__listing .ant-pagination-item-active a {
  background-color: @border-color-15;
  border-radius: 4px;
  color: @text-color-15;
}

.br-serachable-component_empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 5%;
}

.br-serachable-component_empty-state__title {
  height: 20px;
  width: 181px;
  color: @text-color;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 2%;
}

.br-serachable-component_empty-state__subtitle {
  height: 20px;
  width: 316px;
  color: @text-color;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 20px;
}

.br-searchable-component__header .br-filter__trigger {
  margin-left: 10px;
}

.br-searchable-component__empty__icon svg {
  width: 125px;
  height: 89px;
}

.br-searchable-component__empty__title {
  font-family: @poppins-font-600;
  font-size: 14px;
  margin-bottom: 0;
}

.br-searchable-component__empty__sub-title {
  font-family: @lato-font-400;
  font-size: 14px;
  color: @text-color-45;
}

.br-searchable-table-filter-show-search-container {
  display: flex;
  flex-direction: column;
  & div:first-child {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    padding: 0 16px;
  }
}
