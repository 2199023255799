@import 'styles/variables';

.br-select-half-width {
  width: calc(50% - 11px);
}

.br-swapping-tables .br-searchable-component__header .ant-typography {
  width: 100%;
}

.br-packages-debrief__tabs__pickups__thumbnail-container {
  width: 45px;
  margin: auto;
  svg {
    width: 30px;
    height: 30px;
  }
}

.br-packages-debrief__see-more-btn {
  color: @teal-500;
  border-radius: @teal-500;
  height: 28px;
  width: 70px;
  padding: @spacing-xs @spacing-sm @spacing-xs @spacing-sm;
}

.br-packages-debrief__see-more-btn .br-button-component__label {
  color: @teal-500;
}

.br-packages-debrief__see-more-btn span svg path {
  fill: @teal-500;
}
