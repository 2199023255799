@import 'styles/variables';

.br-packages-debrief__title {
  font-size: 18px;
  margin-bottom: 24px;
}

.br-packages-debrief__form {
  align-items: center;
}

.ant-btn.br-packages-debrief__form__submit-button {
  margin-top: 10px;
  height: 32px;
}

.br-packages-debrief__debrief-buttons-wrapper {
  height: 56px;
  display: flex;
  align-items: flex-end;
}

.br-packages-debrief__end-debrief-buttons {
  display: flex;
  gap: 12px;

  .br-packages-debrief__end-debrief-buttons__refresh {
    font-size: 16px;
    padding: 5px;

    &,
    &:hover,
    &:focus {
      color: @text-color-40;
    }

    .anticon {
      line-height: unset;
    }
  }
}

.br-packages-debrief__cards {
  margin: 31px 0;
}

.br-packages-debrief__card {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 80px;
  background: @background-color;
  border: 1px solid @text-color-34;
  border-radius: 4px;
  margin-right: 24px;
  padding: 13px;
}

.br-packages-debrief__card span:first-child {
  font-size: 13px;
  margin-bottom: 5px;
}

.br-packages-debrief__card span:nth-child(2) {
  font-size: 24px;
  font-family: @lato-font-700;
}

.br-packages-debrief__tabs .ant-tabs-tab-btn {
  font-size: 14px;
  font-family: @lato-font-700;
}

.br-packages-debrief__end-debrief-modal__content {
  display: flex;
  flex-direction: column;
}

.br-packages-debrief__end-debrief-modal__content__title {
  font-size: 17px;
  font-family: @lato-font-700;
}

.br-packages-debrief__end-debrief-modal__content span {
  font-size: 14px;
}

.br-packages-debrief__tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: @text-color-29;
}

.br-packages-debrief__tabs .ant-tabs-ink-bar {
  background: @background-color-16;
}

.br-package-debrief__tab-name {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: @lato-font-700;
}

.dot {
  height: 20px;
  width: 20px;
  border: 1px solid #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.success-dot {
  background-color: @green-icon-color;
}

.br-packages-debrief_end-debrief-action,
.br-packages-debrief__end-debrief-modal__footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.br-packages-debrief_end-debrief-action .ant-btn {
  padding: 0 40px;
}

.br-packages-debrief__end-debrief-modal__content
  .br-packages-debrief__end-debrief-modal__content__title {
  display: flex;
  align-items: center;
  font-size: 17px;
  margin-bottom: 15px;
}

.br-packages-debrief__end-debrief-modal__content__title .anticon-close-circle {
  margin-right: 8px;
  font-size: 20px;
  color: red;
}

@media only screen and (max-width: 992px) {
  .br-packages-debrief__card {
    width: 100%;
    margin-bottom: 8px;
  }

  .br-packages-debrief__cards {
    flex-direction: column;
  }
}
