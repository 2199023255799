.br-view-images-modal__wrapper {
  display: flex;
  justify-content: space-between;
}

.br-modal-container {
  min-width: 1000px;
}

.images-section {
  flex: 1;
  max-width: calc(50% - 0.5rem);
  box-sizing: border-box;
}

.images-section .ant-carousel .slick-slide img {
  border-radius: 6px;
}

.images-section .br-images-carousel__footer-wrapper {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.br-view-images-modal__wrapper .images-section:only-child {
  flex: none;
  min-width: 100%;
}

.br-single-image-container {
  min-width: 500px;
}

.images-section__header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.images-section__header svg {
  margin-inline-end: 15px;
}

.br-modal-container .ant-modal-content .ant-modal-close {
  display: block;
  outline: none;
}
