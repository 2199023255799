@import 'styles/variables';

.br-star-logs-hub-star-filters-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  box-shadow: 0px 2px 4px @box-shadow;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid @border-color-23;
  & button.ant-btn {
    height: 44px;
    & span {
      font-size: 16px;
    }
  }
  & .ant-btn.ant-btn-primary {
    background-color: @teal-500;
  }
}

.br-star-logs__actions-row {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}

.br-star-logs-hubs-filter,
.br-star-logs-stars-filter {
  width: 100%;
}

.br-star-logs-hubs-filter__dropdown,
.br-star-logs-stars-filter__dropdown {
  width: 100%;
}

.br-star-logs-table {
  margin-top: 24px;
  flex: 2;

  & .br-searchable-table {
    & .br-searchable-component__header {
      padding-right: 16px;
      & .display-flex {
        align-items: center;
      }

      & h4 {
        margin-left: 16px;
      }

      & .br-star-log__input-search-container {
        margin-bottom: 0;
        padding-right: 8px;
      }
    }
  }

  & .br-searchable-component__empty {
    border: 1px solid @background-color-52;
  }

  & .ant-table-wrapper.br-searchable-table__listing {
    & .ant-table-content {
      border-radius: 0 0 8px 8px;
    }

    & .ant-table-container table > thead > tr:first-child th {
      background-color: @background-color-59;
      border-radius: 0;
    }

    & .ant-table-container table > thead > tr:first-child th:last-child {
      border-top-right-radius: 0;
    }
  }

  & .br-searchable-component__header {
    border: 1px solid @background-color-52;
    border-bottom: 0;
    height: 100%;
    padding-top: 20px;
    border-radius: 8px 8px 0 0;

    & > div {
      width: 70%;
      height: 100%;
    }

    & .br-searchable-component__header__search-input.ant-input-search {
      width: 100%;

      padding: 0 16px;
      border: unset;

      & .ant-input-affix-wrapper {
        border-radius: 8px;
        min-height: 40px;
      }
    }
  }
}

.br-star-logs-call-duration {
  display: block;
  color: @text-color-54;
}

.br-star-logs-delivery-link {
  text-decoration: underline;
}

.br-star-logs-map-container {
  margin-top: 24px;
  flex: 1;
  width: 100%;
  position: sticky;
  top: 0;

  > div {
    width: 100% !important;
    height: 100% !important;
  }
}

.br-star-log__location-action {
  cursor: pointer;
  color: @teal-500;
  & svg {
    margin-left: 8px;
  }
}

.br-star-logs__radio-title {
  font-size: 14px;
}

.br-star-logs__data-container {
  display: flex;
  gap: 24px;
  min-height: 65vh;
}

.br-star-log__input-search-container {
  margin-bottom: 0;
  & .ant-input-group-wrapper {
    height: 32px;
    & .ant-input-wrapper.ant-input-group {
      height: 32px;
      & .ant-input-group-addon .ant-select-open .ant-select-selector,
      .ant-input-group-addon .ant-select-focused .ant-select-selector {
        color: black;
      }
      & .ant-input-group-addon {
        height: 32px;
        background-color: @gray-100;
        &
          .ant-select-focused:not(.ant-select-disabled).ant-select:not(
            .ant-select-customize-input
          )
          .ant-select-selector {
          border: none;
          box-shadow: none;
        }

        & .ant-select.select-before.ant-select-single.ant-select-show-arrow {
          margin-bottom: 0;
          height: 32px;
          padding: 0;
          & :hover {
            border: none;
          }
          & .ant-select-selector {
            display: block;
          }
          &
            .ant-select-single:not(.ant-select-customize-input)
            .ant-select-selector {
            padding: 2px 11px;
          }
          & .ant-select-arrow {
            top: 62%;
          }
        }
      }
    }
  }
  & .ant-input {
    width: 180px;
    border-left: 0;
  }
}

.br-star-logs__star-consignee-details {
  display: flex;
  flex-direction: column;
}

.br-star-logs__filter-menu-content {
  display: flex;
  flex-direction: column;
  & .br-star-logs__filter-title {
    color: @gray-500;
    font-size: 12px;
    height: 25px;
    margin-bottom: 4px;
  }
}

.br-star-logs__filter-component-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
  & .br-analytics-filter__cities-button {
    margin-right: 8px;
  }
}

.br-star-logs__date-filter-header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 27px;
}

.br-star-logs__date-filter-title {
  font-size: 18px;
  color: @gray-700;
  font-family: @poppins-font-400;
}

.br-star-logs__star-action-column-content {
  & svg {
    margin-left: 5px;
    cursor: pointer;
    & #Deliver {
      fill: @gray-400;
    }
  }
}
.br-star-logs__apply-filter-action {
  background-color: @teal-600;
}

.br-star-logs__apply-filter-action.ant-btn-primary[disabled],
.br-star-logs__apply-filter-action.ant-btn-primary[disabled]:hover,
.br-star-logs__apply-filter-action.ant-btn-primary[disabled]:focus,
.br-star-logs__apply-filter-action.ant-btn-primary[disabled]:active {
  background-color: @teal-300;
  color: white;
}

svg.br-star-logs__filter-icon {
  fill: @gray-500;
}

.br-analytics-filter {
  display: flex;
  justify-content: flex-end;
  position: relative;

  & > .ant-btn {
    padding: 6px;
    margin-left: 8px;
  }

  & > button.ant-btn:last-of-type {
    margin: 0;
  }

  & .ant-btn:not(:last-child) {
    margin-right: 8px;
    margin-left: 8px;
  }

  & .ant-btn:not(.ant-btn-circle) {
    height: 36px;
  }
}

.br-analytics-filter__types-dropdown,
.br-analytics-filter__cities-dropdown {
  width: 75%;
}

.br-analytics-filter__cities-dropdown.ant-dropdown {
  border-radius: 2px;
  max-width: 239px;
}

.br-analytics-filter__cities-header,
.br-analytics-filter__type-header {
  background: @background-color-5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid @border-color-20;

  & .ant-btn {
    width: 52px;
  }

  & > span {
    color: @text-color-31;
    font-size: 14px;
  }
}

.br-analytics-filter__cities-dropdown .ant-menu-vertical {
  & .ant-menu-item.ant-menu-item-only-child {
    margin: 0;
    padding: 0 12px;
    height: 100%;
    padding-bottom: 8px;
  }
  & .ant-menu-item.ant-menu-item-only-child.ant-menu-item-active,
  .ant-menu-item.ant-menu-item-only-child.ant-menu-item-selected {
    color: @text-color;
    background-color: white;
  }
}

.br-analytics-filter__cities-dropdown-title {
  margin-left: 7px;
}

.ant-btn.ant-dropdown-trigger.br-analytics-filter__cities-button {
  & .anticon.anticon-down {
    display: none;
  }
}

@media only screen and (max-width: 1300px) {
  .br-star-logs-table .br-searchable-table {
    min-width: unset;
    max-width: 46vw;
  }
}

@media only screen and (max-width: 1250px) {
  .br-star-logs__data-container {
    flex-direction: column-reverse;
    & .br-star-logs-map-container {
      position: unset;
      max-width: 100%;
      margin-left: 0;
    }
    & .br-star-logs-map-container > div {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .br-analytics-container__header {
    & .br-analytics-filter {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;

      & .br-date-picker {
        width: 100%;
        display: block;

        & .ant-picker {
          display: flex;
          justify-content: space-around;
        }
      }

      & > .ant-btn {
        flex-grow: 1;
        flex-basis: 0;
        justify-content: flex-start;
        margin: 0;

        & .br-analytics-filter__types-dropdown-title,
        & .br-analytics-filter__cities-dropdown-title {
          flex-grow: 1;
          text-align: left;
        }
      }
    }
  }

  .br-analytics-filter__cities-dropdown ul {
    max-height: 250px;
  }
}

.br-star-logs__star-polygon-switch {
  display: flex;
  align-items: center;
  gap: 10px;
}
