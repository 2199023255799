@import '~antd/dist/antd.less';
@import './antd-overrides.less';
@import './fonts-variables.less';
@import './variables.less';
@import './fonts.less';

#root {
  height: 100%;
}
.app,
body {
  background-color: white !important;
}

*:focus {
  outline: none;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: @lato-font-400;
}

::-webkit-scrollbar-thumb {
  background-color: @gray-200;
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 16px;
  background: @gray-50;
  border-left: 1px solid @gray-200;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border: 4px solid rgba(@black, 0);
  background-clip: padding-box;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.display-inline {
  display: inline;
}

.display-block {
  display: inline;
}

.display-flex {
  display: flex;
}

.flex-equal-children > * {
  flex: 1;
}

.flex-dir-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-flex-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.br-container-content.br-tabs-container-content {
  padding: 8px 24px;
}
// // .icon-primary{
// //   svg {
// //     fill: @primary-color;
// //   }
// // }

.ant-form-item-has-error.ant-form-item-has-feedback
  .ant-form-item-children-icon,
.ant-form-item-has-success.ant-form-item-has-feedback
  .ant-form-item-children-icon {
  display: none;
}

.main__full {
  padding: 0 8px 0 8px;
}

.br-form-row > *:not(:last-child),
.br-form-with-sub-title > *:not(:last-child) {
  margin-right: 22px;
}

.br-form-row > * {
  flex: 1;
}

.br-form-row__city-area .ant-row.ant-form-item {
  flex: 2 1 auto;
}

.br-form-row__city-area .ant-row.ant-form-item:first-child {
  flex: 1;
}

.br-form-row__city-area .ant-row.ant-form-item:nth-child(2) {
  flex: 2;
}

.br-form-row,
.br-form-with-sub-title,
.br-form-without-label,
.br-form-text-area-label {
  display: flex;
}

.br-form-with-sub-title.ant-row.ant-form-item {
  min-height: 95px;
}

.br-form-with-sub-title.ant-form-item > .ant-form-item-label {
  height: 40px;
}

.br-form-without-label.ant-row.ant-form-item {
  min-height: 50px;
}

.br-form-text-area-label.ant-row.ant-form-item {
  min-height: 110px;
}

.br-form-item-words-count.ant-form-item > .ant-form-item-label > label,
.br-form-item-words-count.ant-form-item > .ant-form-item-label {
  width: 100%;
  justify-content: space-between;
  height: 20px;
}

.br-form-item-words-count.ant-form-item .br-form-words-count-label {
  color: @text-color;
  font-size: 10px;
  font-family: @lato-font-400;
  font-weight: normal;
}

.br-form-item-label__title {
  color: @text-color;
  font-size: 13px;
  font-family: @poppins-font-600;
  font-weight: 500;
  display: flex;
}

.br-form-item-label__sub-title {
  color: @text-color-31;
  font-size: 13px;
  font-family: @lato-font-400;
  font-weight: normal;
}

.br-form-row__half-width {
  max-width: calc(50% - 11px);
}

.br-form-row__city-area .ant-row.ant-form-item {
  flex: 2 1 auto;
}

.br-form-row__city-area .ant-row.ant-form-item:first-child {
  flex: 1;
}

.br-form-row__city-area .ant-row.ant-form-item:nth-child(2) {
  flex: 2;
}

@media only screen and (max-width: 600px) {
  .br-form-row,
  .br-form-with-sub-title {
    flex-direction: column;
  }

  .br-form-row .ant-row.ant-form-item:not(:last-child),
  .br-form-with-sub-title .ant-row.ant-form-item:not(:last-child) {
    margin-right: 0;
  }
}

.br-add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: @text-color-29;
  height: 24px;
  padding: 0;
  font-family: @poppins-font-600;
  font-size: 13px;
  font-weight: 600;
  border: 0;
  box-shadow: none;
}

.br-add-button .anticon {
  font-size: 12px;
  height: 14px;
}

.br-header-back-btn {
  display: flex;
  align-items: center;
  width: fit-content;
  color: #9ba5bc;
  font-family: @poppins-font-600;
  padding-bottom: 30px;
  font-size: 16px;
  font-weight: 600;
}

.br-header-back-btn .anticon {
  font-size: 16px;
  margin-right: 15px;
}

.br-uncovered-area {
  cursor: not-allowed;
}

.br__view__pickups-bulky__tag,
.br__view__pickups-normal__tag {
  color: white;
}

.br__view__pickups-bulky__tag {
  background-color: @text-color-29;
}

.br__view__pickups-normal__tag {
  background-color: @text-color;
}

.ant-form-item .ant-form-item-label .br-form-optional-label {
  color: @text-color-46;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 10px;
  vertical-align: middle;
  margin-left: 3px;
  background: @background-color-62;
  border-radius: 10px;
  font-family: @lato-font-400;
  padding-left: 10px;
  padding-right: 10px;
}

.ant-form-item .ant-form-item-label .br-form-optional-label-new {
  color: @text-color-30;
  font-size: 10px;
  font-family: @lato-font-400;
  vertical-align: middle;
  margin-left: 10px;
  background: @background-color-5;
  padding: 5px;
}

.ant-btn.ant-btn-success,
.ant-btn.ant-btn-success:hover {
  background-color: #00b05c;
  color: #ffffff;
  border-color: #00b05c;
}

.ant-btn.ant-btn-secondry,
.ant-btn.ant-btn-secondry:hover,
.ant-btn.ant-btn-secondry:focus {
  background-color: #6c738a;
  color: #ffffff;
  border-color: #6c738a;
}

.form-label,
select.form-control option {
  font-family: @lato-font-700;
  color: #2d3859;
}

.ant-select-arrow {
  width: auto;
  display: flex;
  align-items: center;
}

.ant-tooltip-inner {
  white-space: pre-line;
}

.word-break-all {
  word-break: break-all;
}

.btn-primary.disabled,
.btn-primary:disabled:not(.ant-dropdown-trigger) {
  color: #fff;
  background-color: #d82020 !important;
  border-color: #d82020 !important;
  cursor: not-allowed !important;
}

//icons classes
.ant-icon-xs {
  font-size: 12px;
  svg {
    width: 12px;
    height: 12px;
  }
}

.ant-icon-sm {
  font-size: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.ant-icon-md {
  font-size: 20px;
  svg {
    width: 20px;
    height: 20px;
  }
}

.ant-icon-lg {
  font-size: 24px;
  svg {
    width: 24px;
    height: 24px;
  }
}

.ant-icon-xl {
  font-size: 32px;
  svg {
    width: 32px;
    height: 32px;
  }
}

.shadow-pages-bg {
  box-shadow: 0px -2px 8px 2px rgba(@deep-teal, 0.16);
}

.br-delete-action .ant-dropdown-menu-title-content,
.br-delete-action .ant-dropdown-menu-title-content span {
  color: @red-700;
}

.ant-dropdown-menu-item-disabled.br-delete-action
  .ant-dropdown-menu-title-content {
  color: @red-300;
  span {
    color: @red-300;
  }
}

.br-filters-search__row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.br-filters-search__container {
  &.ant-collapse {
    background-color: unset;
  }

  .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    align-items: center;
    margin-bottom: 16px;
  }

  .ant-collapse-header-text {
    .font({.display-sm()});
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .ant-collapse-expand-icon {
    svg path {
      fill: @gray-900;
    }
  }

  .ant-picker {
    height: 36px;
  }
}

@media only screen and (max-width: @sm) {
  .br-filters-search__container {
    .ant-row.ant-form-item:not(:last-child) {
      margin-right: 0;
    }
  }

  .br-filters-search__row {
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
  }
}

.br-rounded-dropdown.ant-select.ant-select-single .ant-select-selector {
  border-radius: 100px;

  &,
  .ant-select-selection-search-input {
    height: 25px;
    min-height: unset;
  }
}

.clickable {
  cursor: pointer;
}

.display-none {
  display: none;
}

.reversed-currency {
  display: inline-flex;
  flex-direction: row-reverse;
  gap: 2px;
  padding: 0 2px;
}

// TEMP FIX FOR QUICK RELOAD UNTIL WE UPDATE REACT-SCRIPT
body
  > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

.text-disabled {
  color: @text-gray;
}

.text-wraning {
  color: @text-yellow;
}
