@import 'styles/variables';

@border:1px solid @border-color-13;

.br-information-table__listing .ant-descriptions-row > th,
.br-information-table__listing .ant-descriptions-row:first-child {
  width: 0;
  display: none;
}

.br-information-table__listing.ant-descriptions-bordered
  .ant-descriptions-view {
  border: @border;
  border-radius: 9px;
}

.br-information-table__listing.ant-descriptions-bordered
  .ant-descriptions-row:not(:last-child) {
  border-bottom: @border;
}

.br-information-table__listing.ant-descriptions-bordered
  .ant-descriptions-item-content {
  border-right: none;
}

.br-information-table__listing table tbody {
  height: auto;
}

.br-information-table__listing__field-label {
  margin-bottom: 7px;
}

.br-information-table__listing__field-label {
  color: @text-color-45;
  font-size: 15px;
  font-family: @lato-font-400;
  white-space: nowrap;
}

.br-information-table__listing__field-value .ant-typography {
  font-size: 14px;
  color: @text-color-55;
  line-height: 20px;
  margin-bottom: 0;
  word-break: normal;
  font-family: @poppins-font-400;
  width: 100%;
}

.br-information-table__listing__field-value,
.br-information-table__listing__field-value .ant-typography p {
  margin-bottom: 0;
}

.br-information-table__listing__field-value span p:not(:last-child) {
  margin-bottom: 7px;
}

.br-information-table__listing.ant-descriptions-bordered
  .ant-descriptions-item-content {
  vertical-align: baseline;
  padding: 15px;
}

.br-information-table__listing .ant-descriptions-title {
  color: @text-color-5;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 19px;
  font-family: @lato-font-400;
}

.br-information-table__listing__tag-value .ant-tag {
  font-family: @lato-font-400;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  border-radius: 4px;
}

.br-shipment-details__summary__info .ant-typography.ant-typography-green {
  color: @text-color-29;
}

@media only screen and (max-width: 992px) {
  .br-information-table .ant-descriptions-view .ant-descriptions-row {
    display: flex;
    flex-direction: column;
  }
  .br-information-table .ant-descriptions-view .ant-descriptions-row td {
    border-bottom: @border;
    width: 100%;
  }
}
