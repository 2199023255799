@import 'styles/variables';

.general-info__container {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 2px 4px @gray-200;
  border-radius: 16px;
  padding: 24px;
  border: 1px solid @white;
  background-color: @white;
}

.general-info__header {
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.general-info__title {
  min-width: 140px;
}

.general-info__header div {
  max-width: 150px;
}

.general-info__header svg {
  margin-right: 5px;
}

.general-info__header svg path {
  stroke: @teal-500;
  fill: none;
}

.general-info__data-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 20px;
}

.general-info__total {
  color: @teal-500;
  margin-left: 4px;
}
