@import 'styles/variables';

.br-missing-multi-packages__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;

  & > div {
    width: 100%;
  }
}

.br-missing-multi-packages__actions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.br-missing-multi-packages__content {
  padding: 16px;
  border-radius: 8px;
}

.br-missing-multi-packages__title {
  text-align: center;
  margin-top: 20px;

  > span {
    color: @yellow-600;
  }
}

.br-missing-multi-packages__icon__container {
  display: flex;
  justify-content: center;
}

.br-missing-multi-packages__icon.anticon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @yellow-100;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 8px solid @yellow-50;

  svg path {
    fill: @yellow-600;
  }
}

.br-missing-multi-packages-content__row {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.header > span {
    color: @text-gray;
    text-transform: uppercase;
    .font({.subheading()});
  }
}

.br-missing-multi-packages__content {
  max-height: 300px;
  overflow: auto;

  .ant-divider-horizontal {
    margin: 16px 0;
  }
}

.br-missing-multi-packages__content-items {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .br-multi-package__container > span {
    .font({.body-medium()});
  }
}

.br-missing-multi-packages__content-items {
  .ant-collapse
    > .ant-collapse-item.ant-collapse-no-arrow
    > .ant-collapse-header {
    padding: 0;

    > span {
      width: 100%;
    }
  }

  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: 0px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  .ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    background-color: @yellow-50;
    padding: 0;
    margin-top: 8px;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px;
    }
  }
}

.br-missing-multi-packages__item {
  > span:first-child {
    color: @text-gray;
  }
}

.br-missing-multi-packages__item-scan-status {
  color: @icon-yellow-dark;

  &.br-missing-multi-packages__item-scanned {
    color: @green-600;
  }
}

.br-missing-multi-packages__item-collapse__tracking-number {
  display: flex;
  align-items: center;
  gap: 8px;
}

.br-missing-multi-packages__item-collapse-icon {
  > svg > * {
    fill: @gray-900;
  }
}

.br-missing-multi-packages__item-collapse-icon__active {
  transform: scale(-1);
}
