@import 'styles/variables.less';

.br-empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.br-empty-state__image {
  margin-bottom: 14px;
}

.br-empty-state p {
  margin: 0;
}

.br-empty-state__title {
  color: @text-color;
  font-family: @poppins-font-500;
  font-size: 13px;
}

.br-empty-state__sub-title {
  color: @text-color;
  font-family: @poppins-font-400;
  font-size: 13px;
}
