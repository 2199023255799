@import 'styles/variables';

.br-table-mobile-list.ant-list {
  padding: 16px;
}

.br-table-mobile-cards-with-selection .br-table-mobile-list.ant-list {
  padding-top: 1px;
}

.br-table-mobile-card {
  box-shadow: @shadow-sm;
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 12px;
  display: flex;

  .ant-checkbox-wrapper {
    margin-right: 14px;
  }
}

.br-table-mobile-card > div {
  width: 100%;
}

.br-table-mobile-list {
  .ant-pagination-item:not(.ant-pagination-item-active),
  .ant-pagination-jump-prev.ant-pagination-jump-prev-custom-icon,
  .ant-pagination-jump-next.ant-pagination-jump-next-custom-icon {
    display: none;
  }
  .br-table__actions.ant-btn.ant-dropdown-trigger:hover {
    border: 1px solid @teal-300;
    box-shadow: @shadow-xs-green;
    width: 36px;
    height: 28px;
  }
}

.br-table-mobile-list .ant-pagination-item-active + .ant-pagination-item {
  display: block;
}

.br-table-mobile-card:not(.br-table-mobile-selected-card):hover {
  background-color: @bg-gray-light;
}

.br-table-mobile-card__row,
.br-table-mobile__fixed-header {
  justify-content: space-between;
}

.br-mobile-cards__loading {
  height: 50vh;
  .ant-spin-dot {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .ant-spin-dot .anticon {
    font-size: 42px;
  }
}

.br-mobile-cards {
  // height: 100%;
  box-shadow: @border-top;
}

.br-table-mobile__fixed-header {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 0;
  background-color: @white;
  box-shadow: @border-top;
  padding: 16px;
  width: 100%;
  z-index: 1;
}

.br-table-mobile__fixed-header__with-shadow {
  filter: drop-shadow(0px 4px 4px rgba(29, 41, 57, 0.05));
}

.br-table-mobile__select-checkbox.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled)
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: @gray-400;
  border-color: @gray-400;
}

.br-table-mobile__fixed-header .ant-btn.br-button-component {
  box-shadow: @shadow-xs;
}

.br-table-mobile__fixed-header__left-section,
.br-table-mobile__fixed-header,
.br-table-mobile-card__row {
  display: flex;
  align-items: center;
}

.br-table-mobile__fixed-header__left-section
  .ant-btn.br-button-component__with-prefix {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  align-items: center;
}

.br-table-mobile__fixed-header__left-section
  .ant-btn.br-button-component__with-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.br-table-mobile__select-button-active {
  .br-button-component__label {
    color: @text-gray;
  }
  .ant-checkbox-inner {
    border: 1.5px solid @gray-300;
  }
}

.br-table-mobile-selected-card {
  background-color: @bg-teal-light;
}

.br-mobile-cards__footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 16px 16px 32px 16px;
  background-color: @bg-gray-light;
  box-shadow: @shadow-sm;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.br-mobile-cards__footer__actions {
  display: flex;
}

.br-mobile-cards__footer__label {
  color: @text-gray;
}

.br-table-mobile-card__row .br-table__actions.ant-btn {
  height: 28px;
}
